import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import isoWeek from 'dayjs/plugin/isoWeek'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isToday from 'dayjs/plugin/isToday'
import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { default as minmax, default as minMax } from 'dayjs/plugin/minMax'
import relativeTime from 'dayjs/plugin/relativeTime'
import timeZone from 'dayjs/plugin/timezone'
import toArray from 'dayjs/plugin/toArray'
import utc from 'dayjs/plugin/utc'
import weekday from 'dayjs/plugin/weekday'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import weekOfYear from 'dayjs/plugin/weekOfYear'
// each locale file is about 3.8k. Perhaps we should only import dynamically when needed
import './locales'
import { formatStorage } from './formatStorage'
import type { FormatOptions } from './format.types'

// const dayjsExt = dayjs;
dayjs.extend(localeData)
dayjs.extend(utc)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(customParseFormat)
dayjs.extend(weekday)
dayjs.extend(isBetween)
dayjs.extend(isToday)
dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)
dayjs.extend(timeZone)
dayjs.extend(toArray)
dayjs.extend(minmax)
dayjs.extend(duration)
dayjs.extend(minMax)
dayjs.extend(isoWeek)
dayjs.extend(quarterOfYear)
dayjs.extend(weekOfYear)

export * from './businessTime'
export * from './daysInMonth'
export * from './dst'
export * from './duration'
export * from './essentialTimezones'
export * from './format'
export * from './recurringEvent'
export * from './weekday'
export * from './set'
export * from './intersection'

export type Dayjs = dayjs.Dayjs

export type { ConfigType } from 'dayjs'

function customDayjs(...args: Parameters<typeof dayjs>): dayjs.Dayjs {
  const timezone = formatStorage.getTimeZone()
  // console.log('dayjs timezone', timezone)
  if (timezone) {
    // Get timezone offset for the current timezone
    const offset = dayjs().tz(timezone).utcOffset()
    // Skip timezone handling for UTC (offset 0) to avoid dayjs timezone quirks
    if (offset === 0) {
      return dayjs.tz(dayjs(...args).toISOString(), timezone)
    }
    // Always create a new instance with proper timezone handling
    // Because UTC timezone is treated differently from other timezones in dayjs
    return dayjs(...args).isValid() 
      ? dayjs(...args).tz(timezone, false) // dayjs(dayjs(...args).toISOString()).tz(timezone, false)
      : dayjs(...args)
  }
  // 
  return dayjs(...args)
}

// Copy all properties from original dayjs
Object.assign(customDayjs, dayjs)

// 因为dayjs.timezone plugin修改了startOf方法，但对UTC timezone的处理有问题，所以这里需要重写startOf方法
const originalStartOf = dayjs.prototype.startOf
dayjs.prototype.startOf = function (unit: dayjs.OpUnitType, startOf?: boolean): dayjs.Dayjs {
  const timezone = formatStorage.getTimeZone()
  
  if (!timezone) {
    return originalStartOf.call(this, unit, startOf)
  }

  const offset = dayjs().tz(timezone).utcOffset()
  if (offset !== 0) {
    return originalStartOf.call(this, unit, startOf)
  }
  // 对UTC timezone，需要重新设置timezone
  return originalStartOf.call(this, unit, startOf).tz(timezone)
}

customDayjs.tz = dayjs.tz
customDayjs.utc = dayjs.utc
customDayjs.locale = dayjs.locale
customDayjs.duration = dayjs.duration
customDayjs.max = dayjs.max
customDayjs.min = dayjs.min
customDayjs.unix = dayjs.unix

export { formatStorage, FormatOptions }
export default customDayjs
